@import "variables";

body {
  background-color: $base_color;
  color: white;
  font-family: 'Work Sans', sans-serif;
}

.container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.padded-sides {
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 576px) {
  .container{
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container{
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container{
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container{
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container{
    max-width: 1320px;
  }
}


