.header{
    display: flex;
    position: absolute;
    top: 0;
    background-color: transparent;
    z-index: 100;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 100px;
    z-index: 100;
    & > * {
        padding-top: 30px;
    }

    & h1 {
        color: white;
        margin: 20px;
        text-align: center;

        &.name{
            font-size: 40px;
        }

        &.title{
            font-size: 32px;
        }
    }

    & .button-list{
        display: inline-flex;
        flex-wrap: wrap;
        align-content: center;
        margin: -30px -10px;

        & .rounded-btn{
            margin: 50px 10px;
        }
    }

}

@media only screen and (max-width: 360px){
    .button-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: -30px 0px !important;

        & .rounded-btn{
            margin: 30px 0px !important;
        }
    }
}