@import 'variables';

.rounded-btn {
    & a {
        background-color: transparent;
        color: white;
        border-radius: 40px;
        border: white solid 1px;
        font-size: 16px;
        padding: 15px 30px;
        justify-content: space-between;
        text-decoration: none;

        &:hover{
            background-color: white;
            color: $base_color;
        }
    }
}