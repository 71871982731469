@import "variables";

.modal {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    cursor: auto;

    & button{
        background-color: transparent;
        color: $light_grey;
        outline: none;
        border: none;
        margin: 40px;
        cursor: pointer;
    }

    & img {
        max-width: 300px;
        height: auto;
    }
}

.modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: 80%;
    top:50%;
    left:50%;
    border-radius: 30px;
    transform: translate(-50%,-50%);
    overflow: auto;

    & .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $light_grey;
        margin-bottom: 40px;

        margin: -30px 0px;

        & > * {
            margin: 30px 0px;
        }

        & h2{
            font-size: 40px;
        }

        & p{
            line-height: 1.6em;;
            margin: 10px;
            font-size: 32px;
        }
    }
}

.display {
    display: block;
}

.hide {
    display: none;
}

@media only screen and (max-width: 414px){
    .modal-main {
        text-align: center;
    }
}