.social-media {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 40px;

    & a{
        color: white;
        margin-right: 40px;
        text-decoration: none;
    }

}